import type { Style } from 'mapbox-gl';

export const MAX_ZOOM = 14;
export const DEFAULT_ZOOM = 10;
export const ROAD_POI_ZOOM = 10;

// TODO remove after geoserver update
export const DEFAULT_CENTER: [number, number] = [69.914736, 60.746702];

export const MAP_STYLE: Style = {
    version: 8,
    glyphs: '/fonts_pbf/{fontstack}/{range}.pbf',
    sources: {
      //Основные плитки, близкое маштабирование
      'raster-tiles': {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      },
      //Плитки дальнего маштабирования
      'large-scale': {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      },
      //Слой для записи geojson c информацией о размерах кустов
      'prom-area': {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    },
    layers: [
      {
        id: 'background',
        type: 'background',
        paint: {
          'background-color': '#F2EFEA',
        },
        interactive: true,
      },
      //Топографические плитки (большой маштаб)
      {
        id: 'large-scale-tiles',
        type: 'raster',
        source: 'large-scale',
        maxzoom: 10,
        layout: {
          visibility: 'visible',
        },
      },
      //Топографические плитки (малый маштаб)
      {
        id: 'simple-tiles',
        type: 'raster',
        source: 'raster-tiles',
        minzoom: 9,
        layout: {
          visibility:'visible',
        },
      },
      // Заливка полигонов кутсов
      {
        id: 'well-pad-fill',
        type: 'fill',
        source: 'prom-area',
        minzoom: 9.5,
        paint: {
          'fill-color': '#E7DCC8',
          'fill-opacity': 1,
        },
        layout: {
          visibility: 'visible',
        },
      },
      // Обводка контура (полигонов) кутсов
      {
        id: 'well-pad-contour',
        type: 'line',
        source: 'prom-area',
        minzoom: 9.5,
        paint: {
          'line-color': '#A6A198',
          'line-width': 1.5,
        },
        layout: {
          visibility: 'visible',
        },
      },
    ],
};
